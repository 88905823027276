import BaseService from "../base-service";

class NotificationTypeService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "notification-types",
        });
    }
}

export default new NotificationTypeService();
