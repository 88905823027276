<template>
    <v-card-content>
        <user-edit-notifications-form
            v-if="user"
            :user="user"
            @user-updated="$emit('user-updated')"
        />
    </v-card-content>
</template>

<script>
import UserEditNotificationsForm from "@/components/application/user/user-edit-notifications-form.vue";

export default {
    name: "UsersNotifications",
    components: {
        userEditNotificationsForm: UserEditNotificationsForm,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
};
</script>
