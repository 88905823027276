<template>
    <v-form v-if="user" class="space-y-8" @submit.prevent="submitUserForm(userForm.user_id, userForm)">
        <v-form-group v-if="notificationTypes">
            <v-form-label>Notifications</v-form-label>

            <v-form-group class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                <template v-for="(notification, notificationIndex) in notificationTypes">
                    <v-form-checkbox :key="'notification_' + notificationIndex" v-model="userForm.notifications" :value="notification.notification_type_id" :disabled="isLoading || (!$me.hasPermission('users.update') && user.user_id !== $me.getUser().user_id)">
                        <span class="block">
                            {{ notification.name }}
                        </span>
                    </v-form-checkbox>
                </template>
            </v-form-group>
        </v-form-group>

        <v-form-group class="flex w-full justify-end">
            <v-button class="w-full sm:w-auto" color="primary" text-alignment="center" :disabled="isLoading || (!$me.hasPermission('users.update') && user.user_id !== $me.getUser().user_id)">
                Save Changes
            </v-button>
        </v-form-group>
    </v-form>
</template>

<script>
import Collect from "collect.js";
import NotificationTypeService from "@/services/modules/notification-type-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "UserEditGamesForm",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Game Service
             *
             * @todo Write a description.
             */
            notificationTypeService: NotificationTypeService,

            /**
             * User Service
             *
             * @todo Write a description.
             */
            userService: UserService,

            /**
             * Notification Types
             *
             * @todo Write a description.
             */
            notificationTypes: {},

            /**
             * User Form
             *
             * @todo Write a description.
             */
            userForm: {
                user_id: null,
                notifications: [],
            },
        };
    },
    watch: {
        user: {
            handler({ user_id: userId = null }) {
                this.userService.getNotifications(userId)
                    .then((response) => {
                        this.userForm = {
                            user_id: userId,
                            notifications: this.parseNotifications(response.data.data),
                        };
                    });
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.notificationTypeService.index()
            .then((response) => {
                this.notificationTypes = response.data.data;
            });
    },
    methods: {
        /**
         * Submit User Form
         *
         * @todo Write a description.
         */
        submitUserForm(userId, payload) {
            this.toggleLoading();

            this.userService.updateNotifications(userId, payload)
                .then((response) => {
                    this.$emit("user-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },

        /**
         * Parse Notifications
         *
         * @todo Write a description.
         */
        parseNotifications(notifications) {
            return Collect(notifications).pluck("notification_type_id").toArray();
        },
    },
};
</script>
